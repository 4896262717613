<template>
  <div class="row items-center justify-between">
    <div>
      <PageHeader
        breadcrumb="Store"
        title="Hourly Sales"
        subTitle="How Is My Business Doing?"
      />
    </div>

    <div class="q-gutter-md">
      <Button
        variant="primary"
        label="Export"
        size="lg"
        :disabled="!hasData"
        outline
        @click="exportTable"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_export')" width="30" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Help"
        size="lg"
        outline
        @click="onToggleModal"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_how_to')" width="30" />
        </template>
      </Button>
    </div>
  </div>

  <PageContent>
    <div class="col q-col-gutter-md">
      <div>
        <FilterCard>
          <q-form ref="filterForm" @submit="onFilter" @reset="onReset">
            <div class="row items-start q-col-gutter-md">
              <div class="col-xs-12 col-sm-6 col-md-2">
                <FormDate
                  label="Date From"
                  mask="date"
                  v-model="dateFrom"
                  :value="dateFrom"
                  :rules="[VALIDATION.required]"
                  @input="onSelectFromDate"
                  tabindex="1"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-2">
                <FormDate
                  label="Date To"
                  mask="date"
                  v-model="dateTo"
                  :value="dateTo"
                  :rules="[VALIDATION.required]"
                  @input="onSelectToDate"
                  tabindex="2"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-2">
                <SelectUserStores
                  v-model="store"
                  :values="store"
                  @updateStore="$event => (store = $event)"
                  tabindex="3"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-2">
                <FormSelect
                  label="Show By"
                  v-model="showBy"
                  :values="showBy"
                  :options="showByOptions"
                  :rules="[VALIDATION.required]"
                  :loading="loadingSales"
                  :disabled="loadingSales"
                  tabindex="4"
                />
              </div>

              <div
                class="flex justify-end gap-4 col-xs-12 col-sm-12 col-md-4 text-right"
              >
                <Button
                  variant="primary"
                  label="Reset"
                  icon="refresh"
                  type="reset"
                  outline
                  tabindex="5"
                />
                <Button
                  variant="primary"
                  label="Filter"
                  icon="o_filter_alt"
                  :loading="loadingSales"
                  :disabled="loadingSales"
                  type="submit"
                  tabindex="6"
                />
              </div>
            </div>
          </q-form>
        </FilterCard>
      </div>

      <Loader :loading="loadingSales" />
      <NoDataCard v-if="noData" />

      <div class="col q-col-gutter-md" v-if="hasData">
        <div>
          <q-card class="q-pa-lg">
            <q-card-section>
              <HeatMapChart
                id="HourlySalesChart"
                type="HourlySales"
                axis="xy"
                :data="salesTableData"
                :showBy="showBy"
              />
            </q-card-section>
          </q-card>
        </div>

        <div>
          <Table
            :rows="mappedTimeSales"
            :columns="salesTableHeader"
            :loading="loadingSales"
            hideBottom
          >
            <template v-slot:body-cell-day="props">
              <q-td :props="props">
                <div class="font-semibold">
                  {{ props.row.day }}
                </div>
              </q-td>
            </template>
          </Table>
        </div>
      </div>
    </div>
  </PageContent>

  <Modal
    :show="showModal"
    header="Help"
    width="700px"
    cancelLabel="Close"
    @close="onToggleModal"
    closeOnly
  >
    <h5 class="q-mb-md">Daily Sales</h5>
    <p>Generate daily sales by date range, store</p>

    <q-separator class="q-my-md" />

    <h6 class="q-mb-md">Filters</h6>
    <p><strong>Date From:</strong> required field</p>
    <p><strong>Date To:</strong> required field</p>
    <p><strong>Store:</strong> required field</p>
  </Modal>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue'
import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import { FilterCard, NoDataCard } from '@/components/cards'
import { FormSelect, FormDate } from '@/components/inputs'
import Button from '@/components/Button'
import Table from '@/components/Table'
import Modal from '@/components/Modal'
import { HeatMapChart } from '@/components/charts'
import Loader from '@/components/Loader'
import SelectUserStores from '@/components/customs/SelectUserStores'

import { days, times } from '@/constants'

import {
  DATE,
  FORMAT,
  VALIDATION,
  EXPORT,
  Toast,
  fetchData,
  getIconUrl
} from '@/tools'

export default {
  name: 'StoreHourlySales',
  components: {
    PageHeader,
    PageContent,
    FilterCard,
    NoDataCard,
    FormSelect,
    FormDate,
    Button,
    Table,
    Modal,
    HeatMapChart,
    Loader,
    SelectUserStores
  },
  setup() {
    const { showToast } = Toast()
    const {
      data: dataSales,
      error: errorSales,
      loading: loadingSales,
      post: postSales
    } = fetchData()

    const showModal = ref(false)
    const filterForm = ref(null)
    const dateFrom = ref(DATE.toFriendlyDate(new Date()))
    const dateTo = ref(DATE.toFriendlyDate(new Date()))
    const store = ref([])
    const showBy = ref(null)
    const showByOptions = ref([
      {
        label: 'Transaction Count',
        value: 'trx_count'
      },
      {
        label: 'Sales',
        value: 'sales'
      }
    ])
    const salesTableData = ref(null)
    const salesTableHeader = ref(null)

    const initialTimeSales = days.map(item => ({
      day: item,
      '12AM': 0,
      '01AM': 0,
      '02AM': 0,
      '03AM': 0,
      '04AM': 0,
      '05AM': 0,
      '06AM': 0,
      '07AM': 0,
      '08AM': 0,
      '09AM': 0,
      '10AM': 0,
      '11AM': 0,
      '12PM': 0,
      '01PM': 0,
      '02PM': 0,
      '03PM': 0,
      '04PM': 0,
      '05PM': 0,
      '06PM': 0,
      '07PM': 0,
      '08PM': 0,
      '09PM': 0,
      '10PM': 0,
      '11PM': 0
    }))
    const mappedTimeSales = ref(initialTimeSales)

    const hasData = computed(
      () => salesTableData.value && salesTableData.value.length > 0
    )
    const noData = computed(
      () => salesTableData.value && salesTableData.value.length === 0
    )

    const onToggleModal = () => {
      showModal.value = !showModal.value
    }

    const onFilter = () => {
      filterForm.value.validate().then(success => {
        if (success) {
          const payload = {
            filters: {
              date_from: DATE.toFriendlyDate(dateFrom.value),
              date_to: DATE.toFriendlyDate(dateTo.value),
              stores: store.value
            }
          }

          postSales('v1/sales/hourly-sales', payload)
        } else {
          showToast('Please fill all the required fields', 'info')
        }
      })
    }

    const onReset = () => {
      filterForm.value.resetValidation()
      dateFrom.value = DATE.toFriendlyDate(new Date())
      dateTo.value = DATE.toFriendlyDate(new Date())
      store.value = []
      salesTableData.value = null
    }

    const onSelectFromDate = data => {
      dateFrom.value = data
    }

    const onSelectToDate = data => {
      dateTo.value = data
    }

    const exportTable = () => {
      EXPORT.exportToExcel(
        salesTableHeader.value,
        mappedTimeSales.value,
        'hourly_sales'
      )
    }

    onBeforeMount(() => {
      watch([dataSales, errorSales, loadingSales], () => {
        if (loadingSales.value) {
          salesTableData.value = null
          mappedTimeSales.value = initialTimeSales
        } else {
          if (errorSales?.value) {
            showToast(errorSales?.value?.message, 'danger')
          } else if (dataSales?.value) {
            const listTimes = [
              ...new Set(
                dataSales?.value
                  .sort((a, b) => (a.hour < b.hour ? -1 : 1))
                  .map(item => item.hour)
              )
            ]

            const minTimes = Math.min(...listTimes)
            const maxTimes = Math.max(...listTimes)

            const filteredTimes = times
              .filter(item => item.value >= minTimes && item.value <= maxTimes)
              .map(item => item.name)

            salesTableData.value = dataSales?.value?.map(item => {
              mappedTimeSales.value.find(item2 => item2.day === item.day)[
                item.hour_name
              ] = showBy.value === 'trx_count' ? item.trx_count : item.sales

              return {
                day: item.day,
                time: item.hour.toString().padStart(2, '0'),
                timeName: item.hour_name,
                sales: item.sales,
                trx_count: item.trx_count
              }
            })

            salesTableHeader.value = Object.keys(initialTimeSales[0])
              .map(item => ({
                name: item,
                label: item === 'day' ? 'Day' : item,
                field:
                  item === 'day'
                    ? item
                    : showBy.value && showBy.value === 'trx_count'
                    ? row => FORMAT.toNumber(row[item])
                    : row => FORMAT.toCurrency(row[item]),
                align: 'center',
                sortable: true
              }))
              ?.filter(
                item => filteredTimes.includes(item.name) || item.name === 'day'
              )
          } else {
            salesTableData.value = []
          }
        }
      })
    })

    return {
      showModal,
      filterForm,
      dateFrom,
      dateTo,
      store,
      showBy,
      showByOptions,
      salesTableHeader,
      salesTableData,
      mappedTimeSales,
      loadingSales,
      FORMAT,
      VALIDATION,
      getIconUrl,
      hasData,
      noData,
      onToggleModal,
      onFilter,
      onReset,
      onSelectFromDate,
      onSelectToDate,
      exportTable
    }
  }
}
</script>
