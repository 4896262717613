<template>
  <StoreHourlySales />
</template>

<script>
import StoreHourlySales from '@/components/pages/sales/StoreHourlySales'

export default {
  components: {
    StoreHourlySales
  }
}
</script>
